import React, { useState, useEffect } from 'react'
import { Layout } from 'app/theme/layout'
import { format } from 'date-fns'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const POSTS_PER_PAGE = 21

const fetchBlogPosts = async (page, categorySlug) => {
  try {

    // need to prefetch category if it's in the URL
    const categoryQuery = categorySlug && await fetch(`/wordpress/wp-json/wp/v2/categories?slug=${categorySlug}`)
    const category = categoryQuery && await categoryQuery.json()

    const wpJsonEmbedList = [
      'author',
      'categories',
      'tags',
      'wp:term',
      'wp:featuredmedia'
    ]

    const postFilters = [
      `_embed=${wpJsonEmbedList.join(',')}`,
      `per_page=${POSTS_PER_PAGE}`,
      `page=${page}`
    ]
    if (category?.length) {
      postFilters.push(`categories=${category[0]?.id}`)
    }

    const WP_POST_URL = '/wordpress/wp-json/wp/v2/posts'
    const queryString = postFilters.join('&')

    const response = await fetch(`${WP_POST_URL}?${queryString}`)
    const rawBlogData = await response.json()
    const totalPosts = parseInt(response.headers.get('X-WP-Total'))

    return { data: rawBlogData, meta: { totalPosts }, error: null }
  } catch (error) {
    return { data: null, error: error }
  }
}

const fetchBlogCategories = async () => {
  try {
    const response = await fetch('/wordpress/wp-json/wp/v2/categories?per_page=100')
    const rawBlogCategories = await response.json()
    return { data: rawBlogCategories, error: null }
  } catch (error) {
    return { data: null, error: error }
  }
}

const BlogPage = () => {
  const [posts, setPosts] = useState([])
  const [categories, setCategories] = useState([])
  const [postsError, setPostsError] = useState()
  const [categoriesError, setCategoriesError] = useState()
  const [meta, setMeta] = useState({})
  const params = useParams()

  useEffect(() => {
    !posts.length && fetchBlogPosts(params.page || 1, params.category).then(({data, meta,error}) => {
      if (error) {
        setPostsError(error)
        return
      }
      setPosts(data)
      setMeta(meta)
    })
    !categories.length && fetchBlogCategories().then(({data, error}) => {
      if (error) {
        setCategoriesError(error)
        return
      }
      setCategories(data)
    })
  }, [params])


  // setup pagination
  const MAX_PAGES_TO_SHOW = 10
  const totalPosts = parseInt(meta.totalPosts, 10) 
  const totalPages = Math.ceil(totalPosts / POSTS_PER_PAGE)
  const currentPage = parseInt(params.page, 10) || 1
  const pageArray = []

  // if there are less than MAX_PAGES_TO_SHOW pages, include all of them
  if (totalPages <= MAX_PAGES_TO_SHOW) {
    for (let i = 0; i < totalPages; i++) {
      pageArray.push(i + 1)
    }
  } else {
    // if there are more than MAX_PAGES_TO_SHOW pages, show MAX_PAGES_TO_SHOW pages max
    // the current page should be included in the middle of the array
    const startPage = Math.max(currentPage - Math.floor(MAX_PAGES_TO_SHOW / 2), 0)
    const endPage = Math.min(startPage + MAX_PAGES_TO_SHOW, totalPages - 1)

    for (let i = startPage; i <= endPage; i++) {
      pageArray.push(i + 1)
    }
  }

  // need to account for the category in the structured data
  const structuredData = {
    "@context": "https://schema.org/",
    "@type": "Blog",
    "name": `USMFA Blog${params.category ? ` - ${categories.find(c => c.slug === params.category)?.name}` : ''}`,
    "description": `USMFA Blog${params.category ? ` - ${categories.find(c => c.slug === params.category)?.description}` : ''}`,
    "url": `/blog${params.category ? `/category/${params.category}` : ''}`,
  }

  return (
    <Layout className="container py-10">
      <Helmet>
        <title>{`USMFA Blog${params.category ? ` - ${categories.find(c => c.slug === params.category)?.name}` : ''}`}</title>
        <meta property="og:title" content={`USMFA Blog${params.category ? ` - ${categories.find(c => c.slug === params.category)?.name}` : ''}`} />
        <meta name="description" content={`USMFA Blog${params.category ? ` - ${categories.find(c => c.slug === params.category)?.description}` : ''}`} />
        <link rel="canonical" href={`/blog${params.category ? `/category/${params.category}` : ''}`} />
        <link rel="next" href={`/blog${params.category ? `/category/${params.category}` : ''}/page/${currentPage + 1}`} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:url" content={`/blog${params.category ? `/category/${params.category}` : ''}`} />
        <meta property="og:site_name" content="USMFA" />
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      </Helmet>
      <h1 className="text-5xl font-lusitana">Blog</h1>

      <p className="mt-4 mb-2">Categories:</p>
      <div className="flex flex-wrap">
        {categories?.map(category => (
          <a 
            key={category.id}
            href={`/blog/category/${category.slug}`}
            className={[
              "text-sm mb-2 font-light inline-block text-white rounded-full px-2 mr-1",
              "hover:bg-gray-500",
              params.category === category.slug ? "bg-black" : "bg-gray-800"
            ]}
          >
            {category.name}
          </a>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-12 mt-10">
        {posts?.map(post => <BlogPostPreview key={post.slug} post={post} />)}
      </div>
      <div className="mt-10 flex flex-col items-center">
        <div className="flex items-center">
          {currentPage !== 1 && (
            <a
              href={params.category ? `/blog/category/${params.category}/page/${currentPage - 1}` : `/blog/page/${currentPage - 1}`} 
              className={`mx-2 px-4 py-2 rounded-md bg-black text-white`}
              rel="prev"
              children="Previous"
            />
          )}
          
          {pageArray.map(page => (
            <a 
              key={page} 
              href={params.category ? `/blog/category/${params.category}/page/${page}` : `/blog/page/${page}`}
              className={`mx-2 px-4 py-2 rounded-md ${currentPage === page ? 'bg-black text-white' : 'bg-gray-200'}`}
              children={page}
            />
          ))}
          {currentPage !== totalPages && (
            <a 
              href={params.category ? `/blog/category/${params.category}/page/${currentPage + 1}` : `/blog/page/${currentPage + 1}`} 
              className={`mx-2 px-4 py-2 rounded-md bg-black text-white`}
              children="Next"
              rel="next"
            />
          )}
          
        </div>
        <p className="mt-4 text-sm text-gray-600">
          Showing {(currentPage - 1) * POSTS_PER_PAGE} - {Math.min(currentPage * POSTS_PER_PAGE, totalPosts)} of {totalPosts} posts
        </p>
      </div>
    </Layout>
  )
}

function BlogPostPreview({post}) {

  const postCategories = post._embedded['wp:term'].filter(arr => arr.length && arr[0]?.taxonomy === 'category')

  return (
    <div key={post.id} className="flex flex-col">
      <div className="flex-1">
        {post._embedded['wp:featuredmedia'] && (
          <div 
            className="bg-cover h-0 rounded-lg border border-gray-300" 
            alt="" 
            style={{
              paddingTop:"60%", 
              backgroundImage: `url(${post._embedded['wp:featuredmedia'][0]?.source_url })`, 
            }}
          />
        )}
        <a className="text-xl text-black font-bold my-2" href={`/blog/${post.slug}`}>
          {post.title?.rendered}
        </a>
        <p className="font-light">by {post._embedded?.author[0].name} on {format(new Date(post.date), 'MMM d, yyyy')}</p>
      </div>
      <div className="py-2">
        {postCategories[0]?.map(category => 
          <a 
            key={category.slug}
            href={`/blog/category/${category.slug}`}
            className="text-sm font-light inline-block bg-gray-800 text-white rounded-full px-2 mr-1"
          >
            {category.name}
          </a>
        )}
      </div>
      <div className="flex flex-col">
        <p className="font-light" dangerouslySetInnerHTML={{ __html: post.excerpt?.rendered}} />
        <a href={`/blog/${post.slug}`} children="Read More" className="mt-4 bg-black text-white px-4 py-2 rounded-md self-end" />
      </div>
    </div>
  )
}

export default BlogPage
