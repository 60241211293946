import React, { useMemo, useState } from 'react'
import { Modal } from 'lib/modal'
import { useQuery } from 'jsonapi-react'
import { format } from 'date-fns'
import { X } from 'react-feather'
import styles from './CateringAgreementModal.module.css'
import CateringAgreement from './CateringAgreement'
import Checkbox from 'app/theme/forms/Checkbox'

export default function CateringAgreementModal(props) {
  const {
    isOpen = true,
    closeModal,
    caterer,
    allowAccept,
    onAccept,
  } = props

  const [agreed, setAgreed] = useState(false)

  const query = useQuery(['agreements', {
    sort: '-version',
    filter: {
      type: 'caterer',
    },
    page: {
      size: 1,
    },
  }], {
    ssr: false,
  })

  if (query.isLoading || !query.data || !query.data.length) {
    return null
  }

  const agreement = query.data[0]

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      className={[
        'relative max-w-4xl mx-4 bg-white',
        allowAccept && caterer && 'p-4',
      ]}
    >
      <button
        type="button"
        onClick={closeModal}
        className={[
          'btn p-2 rounded-full bg-white hover:bg-gray-200',
          'absolute z-40 right-0 top-0 -mr-3 -mt-3 shadow',
        ]}
        children={
          <X size={20} />
        }
      />
      <CateringAgreement
        agreement={agreement}
        maxHeight="70vh"
      />
      {allowAccept && caterer && (
        <div className="pt-6 pb-3 flex justify-between">
          <Checkbox
            size="lg"
            label="I agree to the Vendor Catering Agreement"
            onChange={checked => {
              setAgreed(checked)
            }}
          />
          <button
            children="Accept Terms"
            disabled={!agreed}
            className={[
              'btn btn-white px-6 py-2',
              !agreed && 'opacity-50 cursor-default',
            ]}
            onClick={() => {
              query.client.mutate('caterer_agreements', {
                caterer: { id: caterer.id },
                agreement,
              }).then(res => {
                closeModal()
                if (res.data && onAccept) {
                  onAccept(res.data)
                }
              })
            }}
          />
        </div>
      )}
    </Modal>
  )
}
