import React from 'react'
import { Layout } from 'app/theme/layout'
import BackgroundCover from 'app/theme/BackgroundCover'
import { Helmet } from 'react-helmet'
import coverImage from 'assets/img/build-bg.jpg'
import styles from './MarketplacePage.module.css'

export default function MarketplacePage() {

  return (
    <Layout>
      <Helmet>
        <title>Buy, Sell, Lease or Build a Food Truck - Mobile Food Alliance</title>
        <meta name="description" content="The one-stop spot for launching your food truck business or selling your food truck." />
        <meta name="og:title" content="Buy, Sell, Lease or Build a Food Truck - Mobile Food Alliance" />
      </Helmet>
      <BackgroundCover
        imageUrl={coverImage}
      >
        <div className={[styles.hero_content_wrapper, "w-full"]}>
          <div className={styles.hero_content}>
            <h1 className="font-lusitana text-4xl lg:text-5xl text-center leading-tight mb-1 text-white">
              Welcome to the Marketplace
            </h1>
            <p className="font-light text-xl lg:text-3xl text-center leading-tight mb-8 text-white">
              The one-stop spot for launching your food truck business or selling your food truck.
            </p>

            <ul className="text-white font-bold text-xl text-center mt-5 mb-2">
              <li className={[styles.list_item, "block lg:inline-block m-3 lg:m-5"]}>
                <a href="/marketplace/buy-a-food-truck/">Buy A Truck</a>
              </li>
              <li className={[styles.list_item, "block lg:inline-block m-3 lg:m-5"]}>
                <a href="/marketplace/build-a-food-truck/">Build A Truck</a>
              </li>
              <li className={[styles.list_item, "block lg:inline-block m-3 lg:m-5"]}>
                <a href="/marketplace/lease-a-food-truck/">Lease A Truck</a>
              </li>
              <li className={[styles.list_item, "block lg:inline-block m-3 lg:m-5"]}>
                <a href="/marketplace/sell-a-food-truck/">Sell My Food Truck</a>
              </li>
            </ul>
          </div>
        </div>
      </BackgroundCover>
    </Layout>
  )
}
