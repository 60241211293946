import React, { useState, useEffect, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ChevronDown, Menu, X } from 'react-feather'
import { useSession } from 'app/session'
import logo from 'assets/img/USMFA_logo.png'
import { TrackableNavLink, TrackableLink } from 'app/theme'
import styles from './Header.module.css'
import arrowDown from 'assets/svg/arrow-down.svg'
import posthog from 'posthog-js'

const marketplaceLinks = [
  { path: "/marketplace/buy-a-food-truck/", label: "Food Trucks for Sale" },
  { path: "/marketplace/build-a-food-truck/", label: "Custom Built Food Trucks" },
  { path: "/marketplace/lease-a-food-truck/", label: "Food Truck Rentals/Leases" },
  { path: "/marketplace/sell-a-food-truck/", label: "Sell Your Food Truck" },
];

const resourceLinks = [
  { path: "/blog", label: "Blog" },
];

const memberDashboardLinks = [
  { path: "/catering", label: "Catering" },
  { path: "/public-events", label: "Vending" },
  { path: "/calendar", label: "My Calendar" },
];

const memberServicesLinks = [
  { path: "/catering-leads", label: "Catering Leads" },
];

export default function Header() {
  const session = useSession()
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(styles.open)
    }
    return () => {
      if (isOpen) {
        document.body.classList.remove(styles.open)
      }
    }
  }, [isOpen])

  return (
    <header className={[
      styles.header,
      'sticky md:relative z-20 flex flex-col w-full top-0 bg-white shadow font-alexandria',
    ]}>
      <div className={[
        styles.nav,
        'container flex items-center justify-center md:justify-between'
      ]}>
        <TrackableLink to="/" title="US Mobile Food Alliance" className="md:mr-10">
          <img src={logo} width={98} />
        </TrackableLink>

        <nav className="hidden md:flex flex-1 h-full items-center  pb-2 justify-between">
          <div className="flex h-full lg:text-lg">
            <NavItemTrackableLink title="Food Trucks" links={marketplaceLinks} to="/marketplace/" />
            <NavItemTrackableLink title="Resources" links={resourceLinks} />
            {session.isLoggedIn ? (
              <NavItemTrackableLink title="Member Dashboard" links={memberDashboardLinks} />
            ) : (
              <NavItemTrackableLink title="Member Services" links={memberServicesLinks} />
            )}
          </div>
          {session.isLoggedIn ? (
            <DropDown session={session} />
          ) : (
            <AuthButtons />
          )}
        </nav>

        {/* Mobile menu trigger open/close */}
        <button
          type="button"
          className={[
            'p-2 absolute right-0 mr-3 rounded-md focus:outline-none',
            'hover:bg-gray-300 focus:bg-gray-300 md:hidden'
          ]}
          onClick={() => {
            posthog.capture('mobile_menu_clicked')
            setOpen(open => !open)
          }}
          children={
            isOpen ? (
              <X className="text-black" size={26} />
            ) : (
              <Menu className="text-black" size={26} />
            )
          }
        />
      </div>
      
      {/* Mobile menu */}
      <MobileMenu session={session} setOpen={setOpen} />
    </header>
  )
}

const NavItemTrackableLink = ({ title, links, to }) => {
  const location = useLocation();

  const navLinkClass = [styles.navLink, location.pathname.includes(to || links[0].path) ? "border-b-4 border-black" : 'border-white'].join(' ');

  return (
    <div className={[styles.navItem, "relative"]}>
      {to ? (
        <TrackableLink to={to} className={navLinkClass}>
          {title} &nbsp; <img src={arrowDown} className="w-3 h-3 mt-1" />
        </TrackableLink>
      ) : (
        <p className={navLinkClass}>
          {title} &nbsp; <img src={arrowDown} className="w-3 h-3 mt-1" />
        </p>
      )}
      
      <div className={styles.subnav}>
        {links.map(link => (
          <TrackableLink key={link.path} to={link.path} className={styles.subnavItem}>
            {link.label}
          </TrackableLink>
        ))}
      </div>
    </div>
  );
};

const AuthButtons = () => (
  <div className="flex items-center h-full lg:text-lg">
    <div className="cursor-pointer px-4 py-2 mr-2 btn-white whitespace-no-wrap">
      <TrackableLink to="/login" children="Login" />
    </div>
    <div className="cursor-pointer px-4 py-2 btn-orange whitespace-no-wrap">
      <TrackableLink to="/get-started" children="Get Started" />
    </div>
  </div>
);

const MobileMenu = ({ session, setOpen }) => (
  <div className={[styles.menu, "text-md hover:text-gray-700"]}>
    <div className="container py-3">
      <p className="font-bold">Food Trucks</p>
      {marketplaceLinks.map(link => (
        <TrackableLink key={link.path} to={link.path} className={styles.subnavItem}>
          {link.label}
        </TrackableLink>
      ))}
    </div>
    <div className="container py-3 border-t border-black">
      <p className="font-bold">Resources</p>
      {resourceLinks.map(link => (
        <TrackableLink key={link.path} to={link.path} className={styles.subnavItem}>
          {link.label}
        </TrackableLink>
      ))}
    </div>
    <div className="container py-3 border-t border-black">
      <p className="font-bold">Member Services</p>
      <TrackableLink to="/catering-leads" className={styles.subnavItem}>Catering Leads</TrackableLink>
    </div>

    {session.isLoggedIn ? (
      <>
        <div className="container py-3 border-t border-black text-black">  
          <NavTrackableLink to="/account" children="My Account"/>
        </div>
        <div className="container py-3 border-t border-black">
          {memberDashboardLinks.map(link => (
            <NavTrackableLink key={link.path} to={link.path} children={link.label} />
          ))}
        </div>
        <div className="container py-3 border-t border-black">
          <SettingsLinks session={session} />
        </div>
        {session.user.caterers.length > 0 && (
          <div className="container border-t border-black py-3">
            {session.user.caterers.map(caterer => (
              <Link
                key={caterer.id}
                to="#"
                children={caterer.name}
                className={session.caterer.id === caterer.id ? styles.active : undefined}
                onClick={() => {
                  session.setCaterer(caterer)
                  setOpen(false)
                }}
              />
            ))}
          </div>
        )}
        <div className="container border-t border-black py-3">
          <TrackableLink to="/logout" children="Log Out" />
        </div>
      </>
    ) : (
      <div className="container py-3 border-t border-black">
        <p className="font-bold">Account</p>
        <TrackableLink to="/login" children="Log In" />
        <TrackableLink to="/get-started" children="Get Started" />
      </div>
    )}
  </div>
);

function NavTrackableLink(props) {
  return <TrackableNavLink {...props} activeClassName={styles.active} />
}

function SettingsLinks({ session }) {

  return (
    <>
      <NavTrackableLink to="/settings/profile" children="Caterer Profile" />
      <NavTrackableLink to="/settings/services" children="Services" />
      <NavTrackableLink to="/settings/menu" children="Menu" />
      <NavTrackableLink to="/settings/packages" children="Packages" />
      <NavTrackableLink to="/settings/members" children="Members" />
      <NavTrackableLink to="/settings/documents" children="Terms & Documents" />
      <NavTrackableLink to="/settings/payment-details" children="Payment Details" />
      {session?.caterer?.settings?.tipalti_enabled && (
        <>
          <NavTrackableLink to="/settings/payment-invoices" children="Payment Invoices" />
          <NavTrackableLink to="/settings/payment-history" children="Payment History" />
        </>
      )}
    </>
  )
}

// For authenticated users
function DropDown({ session }) {
  const ref = useRef()
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    const onOutsideClick = ({ target }) => {
      if (
        target.tagName === 'A' ||
        ref.current &&
        ref.current !== target &&
        !ref.current.contains(target)
      ) {
        setOpen(false)
      }
    }

    if (isOpen) {
      document.addEventListener('click', onOutsideClick)
    }

    return () => {
      if (isOpen) {
        document.removeEventListener('click', onOutsideClick)
      }
    }
  }, [isOpen])

  if (!session.isLoggedIn) {
    return null
  }

  return (
    <div className="relative h-full">
      <button
        type="button"
        onClick={() => setOpen(open => !open) }
        className={[
          'flex items-center text-black font-semibold',
          'h-full lg:text-lg focus:outline-none',
        ]}
      >
        {session.caterer.logo && (
          <div
            className={[
              'hidden lg:block',
              'mr-3 bg-contain bg-no-repeat bg-center'
            ]}
            style={{
              width: 40,
              height: 40,
              backgroundImage: `url(${session.caterer.logo.url})`,
            }}
          />
        )}
        <span>{session.caterer.name}</span>
        <ChevronDown
          size={20}
          className={[
            'ml-1 mt-1 transform transition-all duration-75',
            isOpen && 'rotate-180',
          ]}
        />
      </button>
      <div ref={ref} className={[styles.dropDown, isOpen && styles.open]}>
        <div className="py-4 px-6 leading-tight font-normal text-xl">
          <div className="truncate">
            {[
              session.user.first_name,
              session.user.last_name,
            ].filter(Boolean).join(' ')}
          </div>
          <div className="text-sm text-gray-700 truncate font-light">
            {session.user.email}
          </div>
        </div>
        <div className="border-t border-gray-200 py-2">
          <TrackableLink to="/account" children="My Account" />
        </div>
        <div className="border-t border-gray-200 py-2">
          <SettingsLinks session={session} />
        </div>
        {session.user.caterers.length > 0 && (
          <div className="border-t border-gray-200 py-2">
            {session.user.caterers.map(caterer => (
              <Link
                key={caterer.id}
                to="#"
                children={caterer.name}
                onClick={() => {
                  session.setCaterer(caterer)
                  setOpen(false)
                }}
              />
            ))}
          </div>
        )}
        <div className="border-t border-gray-200 py-2">
          <TrackableLink to="/logout" children="Sign Out" />
        </div>
      </div>
    </div>
  )
}
