import React from 'react'
import icon1 from 'assets/svg/heart-balloon.svg'
import icon3 from 'assets/svg/burger.svg'

export default function Features() {
  return(
    <section  className="py-20">
      <div className="container">
        <h2 className="text-navy leading-none mb-20 font-lusitana text-center text-5xl">Grow Your Business</h2>
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 mb-10">
            <div className="mx-4 p-8 h-full border">
              <img className="mb-8 mx-auto" style={{width: "100px", height: "100px"}} src={icon1} alt="Heart Balloon" />
              <h4 className="text-navy text-2xl text-center leading-none mb-2 font-alexandria">Book More Events</h4>
              <p className="font-alexandria font-light text-lg">We’ve been refining our booking platform for 10+ years. Now you can use our proprietary Lead Capture and Proposal Maker on your own site and social media channels to capture and book your own leads with ease.</p>
            </div>
          </div>
          <div className="w-full md:w-1/2 mb-10">
            <div className="mx-4 p-8 h-full border">
              <img className="mb-8 mx-auto" style={{width: "100px", height: "100px"}} src={icon3} alt="Burger" />
              <h4 className="text-navy text-2xl text-center leading-none mb-2 font-alexandria">Find More Customers</h4>
              <p className="font-alexandria font-light text-lg">Help customers in your city find you with a custom profile and get orders on Roaming Hunger’s city, map, and landmark pages.</p>
            </div>
          </div>
        </div>
        <div className="text-center">
          <a className="btn btn-white inline-block text-center px-12 py-3 font-lusitana text-2xl" href="/get-started">Get Started</a>
        </div>
      </div>
    </section>
  )
}
