import React from 'react'
import { Layout } from 'app/theme/layout'
import { Helmet } from 'react-helmet'
import { TrackableLink } from 'app/theme'
import BackgroundCover from 'app/theme/BackgroundCover'
import heroBuildTruck from 'assets/img/build-bg.jpg'
import styles from './BuildTruck.module.css'

function WhyBuildFoodTruck() {
  return (
    <div className="container m-auto text-center py-12">
      <h2 className="text-5xl font-light font-lusitana leading-tight mb-10">
        Why Build A Food Truck?
      </h2>
      <div className="container p-12 items-baseline text-left text-2xl flex justify-between mb-8 md:flex-no-wrap sm:flex-wrap flex-wrap">
        <div className="flex justify-center mb-20 sm:w-full md:w-1/2 h-12 pl-0 md:pl-10">
          <div className="container p-0">
            <ul className={[styles.whyBuildList, 'list-disc']}>
              <li><span>Financing Available</span></li>
              <li><span>Catering Event Inclusion</span></li>
              <li><span>Start At $70K</span></li>
            </ul>
          </div>
        </div>
        <div className="flex justify-center mb-20 mt-10 md:mt-0 sm:w-full md:w-1/2 h-12">
          <div className="container p-0">
            <ul className={[styles.whyBuildList, 'list-disc']}>
              <li><span>Fully Customized, Down to the Rims</span></li>
              <li><span>Network of Best Builders in the Country</span></li>
              <li><span>Your Menu. Your Truck.</span></li>
            </ul>
          </div>
        </div>
      </div>
      <hr className="mt-20 md:mt-0" />
    </div>
  )
}

function HowItWorks() {

  return (
    <section className={[styles.how_it_works_container, "w-full mb-12"]}>
      <h1 className="text-3xl lg:text-4xl font-lusitana text-navy font-bold text-center leading-tight pt-10 mb-12">How It Works</h1>

      <div className="container md:flex md:flex-row">
        <div className="w-full md:flex-1 text-center mb-10 px-2">
          <div className={[styles.how_it_works_circle, "font-light inline text-center m-auto text-4xl bg-white"]}>1</div>
          <p className="font-normal text-xl text-navy leading-normal my-4">Give us the details</p>
          <p className="font-light text-lg text-navy leading-normal my-4">Tell us about yourself, your business goals, and the type of truck you’d like to build.</p>
        </div>
        <div className="w-full md:flex-1 text-center mb-10 px-2">
          <div className={[styles.how_it_works_circle, "font-light inline text-center m-auto text-4xl bg-white"]}>2</div>
          <p className="font-normal text-xl text-navy leading-normal my-4">Complete the application process</p>
          <p className="font-light text-lg text-navy leading-normal my-4">Our partners have the lowest interest rates in the industry once you pass the credit check.</p>
        </div>
        <div className="w-full md:flex-1 text-center mb-10 px-2">
          <div className={[styles.how_it_works_circle, "font-light inline text-center m-auto text-4xl bg-white"]}>3</div>
          <p className="font-normal text-xl text-navy leading-normal my-4">Work with a top builder</p>
          <p className="font-light text-lg text-navy leading-normal my-4">You’re going to get a top of the line food truck from one of the best builders in the country.</p>
        </div>
      </div>
      <div className="container md:w-3/4 lg:w-1/2 md:flex md:flex-row">
        <div className="w-full md:flex-1 text-center mb-10 px-2">
          <div className={[styles.how_it_works_circle, "font-light inline text-center m-auto text-4xl bg-white"]}>4</div>
          <p className="font-normal text-xl text-navy leading-normal my-4">Hit the road</p>
          <p className="font-light text-lg text-navy leading-normal my-4">Three to six months later, your mobile food business will be ready to roll.</p>
        </div>
        <div className="w-full md:flex-1 text-center mb-10 px-2">
          <div className={[styles.how_it_works_circle, "font-light inline text-center m-auto text-4xl bg-white"]}>5</div>
          <p className="font-normal text-xl text-navy leading-normal my-4">Build your business</p>
          <p className="font-light text-lg text-navy leading-normal my-4">Get plugged into our suite of services, from advertising to revenue opportunities. See all benefit details below.</p>
        </div>
      </div>
      <div className="w-full flex items-center">
        <TrackableLink to="/start-a-food-truck/" className="m-auto btn-white py-3 px-6 text-center text-2xl text-bold">Get Started</TrackableLink>
      </div>
    </section>
  )
}

export default function BuildTruckPage() {
  return (
    <Layout>
      <Helmet>
        <title>Build a Custom Food Truck or Trailer - USMFA</title>
        <meta name="description" content="Custom food trucks built to spec for mobile food vending or promotions. Financing available for any budget. Food trucks, vans, carts & trailers." />
        <meta name="og:title" content="Build a Custom Food Truck or Trailer - USMFA" />
      </Helmet>
      <BackgroundCover className={styles.hero} src={heroBuildTruck}>
        <div className="container text-center text-white">
          <h1 className="font-lusitana text-5xl text-center leading-tight mb-3 text-white">
            Custom Food Trucks
          </h1>
          <p className="font-light text-3xl text-center leading-tight mb-5 text-white">
            Build your Perfect Food Truck
          </p>
          <TrackableLink to="/start-a-food-truck/" className="btn btn-white font-lusitana text-3xl px-10 py-2">
            Get Rolling
          </TrackableLink>
        </div>
      </BackgroundCover>
      <WhyBuildFoodTruck />
      <HowItWorks />
    </Layout>
  )
}
