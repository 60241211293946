import React from 'react'
import computer from 'assets/svg/computer.svg'
import cake from 'assets/svg/wedding-cake.svg'
import cap from 'assets/svg/graduation-cap.svg'
import mic from 'assets/svg/mic.svg'
import film from 'assets/svg/film.svg'
import balloons from 'assets/svg/balloons.svg'
import briefcase from 'assets/svg/briefcase.svg'
import partyHat from 'assets/svg/party-hat.svg'
import styles from './Occasions.module.css'

export default function Occasions(props) {
  const {
    heading = 'We get hundreds of event requests every single day',
    ...rest
  } = props

  return (
    <div {...rest} className="bg-white">
      <div className="container py-16 lg:py-20">
        <h2 className="font-lusitana leading-none text-navy text-4xl md:text-5xl text-center mb-12">
          {heading}
        </h2>
        <div className="md:flex md:flex-wrap md:-mx-4">
          <Item
            title="Workplace Catering"
            icon={computer}
          >
            Catered Food Truck Lunch Programs, In-Office Meal Programs, Business Park Lunches, Employee Appreciation, Client Appreciation, Holiday Parties, Milestone Celebrations, Retail Events
          </Item>
          <Item
            title="Wedding Catering"
            icon={cake}
          >
            Wedding Receptions, Rehearsal Dinners, Late Night Snacks, Day-After Brunches
          </Item>
          <Item
            title="Food Trucks on Campus"
            icon={cap}
          >
            Student Organizations, On/Off-Campus Events, Graduation, Prom, School Dances, School Fundraisers, Recruitment Events, Alumni Events
          </Item>
          <Item
            title="Large-Scale Events and Multi-City Event Series"
            icon={mic}
          >
            Sporting Events, Music Festivals, Arena Concerts, National Event Series, Marathons and Fun Runs
          </Item>
          <Item
            title="Production Catering"
            icon={film}
          >
            Movie Set Catering, TV and Film Catering, 24 Hour Production Catering, On Location Catering, Photo Shoots
          </Item>
          <Item
            title="Community Events"
            icon={balloons}
          >
            Street Fairs, Neighborhood Block Parties, Flea Markets, Little League Games, Car Shows, Real Estate Open Houses, Religious Congregations and Ministries, High School Football Games, Concerts in the Park
          </Item>
          <Item
            title="Professional Events"
            icon={briefcase}
          >
            Conferences, Conventions, Expos
          </Item>
          <Item
            title="Private Parties"
            icon={partyHat}
          >
            Birthday, Holiday, Graduation, Anniversary, Bar/Bat Mitzvah, Baby Shower, House Warming
          </Item>
        </div>
      </div>
    </div>
  )
}

function Item({ children, url, icon, title }) {
  return (
    <div className={[styles.item, 'md:w-1/2 mb-5 flex p-3']}>
      <i><img src={icon} alt={title} /></i>
      <div>
        <h3 className="text-navy text-2xl md:text-xl lg:text-2xl">
          {title}
        </h3>
        <p className="font-light text-lg">{children}</p>
      </div>
    </div>
  )
}
