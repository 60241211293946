import React from 'react'
import { Link } from 'react-router-dom'
import { useSession } from 'app/session'
import { format } from 'date-fns'
import logo from 'assets/svg/logo-no-circle.svg'
import env from 'env'

export default function Footer() {
  const session = useSession()

  return (
    <footer className="bg-white py-12 font-alexandria border-t border-gray-200 font-light text-base leading-relaxed text-gray-900">
      <div className="container">
        <div className="flex flex-col sm:flex-row">
          <div className="w-full sm:w-1/3 px-4 mb-4">
            <ul>
              {session.isLoggedIn ? (
                <>
                  <li><Link to="/catering" className="text-blue-700 hover:underline">Catering</Link></li>
                  <li><Link to="/public-events" className="text-blue-700 hover:underline">Vending</Link></li>
                  <li><Link to="/bookings" className="text-blue-700 hover:underline">My Bookings</Link></li>
                  <li><a href="https://roaminghunger.helpscoutdocs.com/" className="text-blue-700 hover:underline">Frequently Asked Questions</a></li>
                </>
              ) : (
                <>
                  <li><a href="/" className="text-blue-700 hover:underline">Home</a></li>
                  <li><a href="/get-started" className="text-blue-700 hover:underline">Get Started</a></li>
                  <li><a href="/login" className="text-blue-700 hover:underline">Login</a></li>
                </>
              )}
              <li><hr className="my-4" /></li>
              <li>© {format(new Date(), 'yyyy')} Mobile Food Alliance</li>
              <li className="mt-2 text-xs">
                <a className="text-blue-700 mr-1 hover:underline" href="https://roaminghunger.com/pages/privacy_policy/" target="_blank">Privacy Policy</a>
                 | <a className="text-blue-700 hover:underline" href="https://roaminghunger.com/pages/terms_and_conditions/" target="_blank">Terms &amp; Conditions</a>
              </li>
              <li className="mt-2 text-xs">Version: {env.APP_VERSION || 'n/a'}</li>
            </ul>
          </div>
          <div className="w-full sm:w-1/3 px-4 mb-4">
            <p className="sm:text-center">
              <strong>QUESTIONS?</strong>
              <br />CALL US Monday-Friday 9-5PM PST 
              <br />(888) 241-3812
              <br /><a className="text-blue-700 hover:underline" href="mailto:vendors@usmfa.com">vendors@usmfa.com</a>
            </p>
          </div>
          <div className="w-full sm:w-1/3 px-4 mb-4"></div>
        </div>
      </div>
    </footer>
  )
}
