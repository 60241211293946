import React, { useState, useEffect } from 'react'
import { X } from 'react-feather'

const HeaderBanner = ({ message = 'Important announcement' }) => {
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    const bannerState = localStorage.getItem('headerBannerHidden')
    if (bannerState === 'true') {
      setIsVisible(false)
    }
  }, [])

  const handleClose = () => {
    setIsVisible(false)
    localStorage.setItem('headerBannerHidden', 'true')
  }

  if (!isVisible) return null

  return (
    <div className="relative bg-black">
      <div className="max-w-7xl container relative mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="pr-16 xs:text-center font-alexandria">
          <p className="font-light text-white">
            {message}
          </p>
        </div>
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
          <button
            type="button"
            className="flex p-2 rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-white"
            onClick={handleClose}
          >
            <span className="sr-only">Dismiss</span>
            <X className="h-6 w-6 text-white" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default HeaderBanner
